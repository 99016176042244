.redirection-container {
  height: 80vh;
  width: 80vw;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #fffdfd;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.redirection-title {
  color: #044064;
  font-weight: bold;
  font-family: 'Almarai', sans-serif;
  font-size: 3rem;
}

.company-logo {
  max-width: 225px;
}

.redirection-animation {
  max-width: 120px;
  transform: rotate(90deg);
}

@media (max-width: 576px) {
  .redirection-title {
    font-size: 2rem;
  }

  .company-logo {
    max-width: 120px;
  }

  .redirection-animation {
    max-width: 80px;
  }

  .redirection-container {
    height: 100vh;
    width: 100vw;
  }
}